<!-- BCT: Added by BCT -->
<!-- BCT: Added by BCT -->
<!-- BCT: Added by BCT -->
<template>
  <div>
    <hb-modal
      size="large"
      :title="(editMode ? 'View / Edit' : 'Add') + ' Feature and Amenity'"
      v-model="dialog"
      @close="onCloseConfirmationDialog"
      :footerCancelOption="true"
      show-help-link
    >
      <!-- Main content -->
      <template v-slot:content>
        <hb-modal
          v-model="showConfirmationDialog"
          size="medium"
          title="Discard Setup"
          confirmation
          show-help-link
        >
          <!-- Confirmation Modal -->
          <template v-slot:content>
            <div class="px-6 py-4">
              Are you sure you want to discard this setup?
            </div>
            <div class="px-6 pb-4">
              If you do, you will lose all the information you’ve entered.
              Continue the setup process to ensure Hummingbird saves your
              information.
            </div>
          </template>
          <template v-slot:right-actions>
            <hb-btn color="primary" @click="$emit('close')">Discard</hb-btn>
          </template>
        </hb-modal>
        <!-- Tabs -->
        <hb-stepper v-model="stepper">
          <hb-stepper-header>
            <hb-stepper-step
              @click="goToStep(1)"
              :complete="steps[0].status == 'step-complete'"
              :rules="[() => steps[0].status !== 'step-error']"
              key="1-step"
              step="1"
            >
              Define Values
            </hb-stepper-step>

            <v-divider></v-divider>

            <hb-stepper-step
              @click="goToStep(2)"
              :complete="steps[1].status == 'step-complete'"
              :rules="[() => steps[1].status !== 'step-error']"
              key="2-step"
              step="2"
            >
              Apply Defaults
            </hb-stepper-step>
          </hb-stepper-header>

          <v-stepper-items>
            <template v-if="editMode">
              <div class="px-4 py-4">
                <hb-notification
                  type="caution"
                  title="Warning!"
                  :not-dismissable="true"
                >
                  Tenant HIGHLY recommends that you do not rename these features
                  and amenities. They have been selected to match the customers
                  understanding. Additionally these terms are more relavant for
                  SEO than alternates.
                </hb-notification>
              </div>
            </template>
            <v-divider></v-divider>

            <!-- Define Values screen -->
            <v-stepper-content class="pa-0" key="1-content" step="1">
              <div>
                <!-- Amenity Name -->
                <hb-form label="Name">
                  <HbTextField
                    class="pt-0"
                    v-model="amenityDetail.property_amenity_name"
                    v-validate="`required`"
                    data-vv-name="Name"
                    data-vv-as="Name"
                    single-line
                    label="Enter a name"
                    :error-messages="errors.collect(`Name`)"
                    id="Name"
                    name="Name"
                    hide-details
                  >
                  </HbTextField>
                </hb-form>

                <!-- Amenity Category -->
                <hb-form label="Category">
                  <template>
                    <div
                    class="pb-3 hb-text-light hb-font-body"
                    >
                      Select a category for this feature and amenity
                    </div>
                  </template>
                  <HbSelect
                    :items="categories"
                    item-text="name"
                    item-value="id"
                    single-line
                    label="Select a Type"
                    v-model="amenityDetail.amenity_category_id"
                    name="category"
                    id="category"
                    data-vv-name="category"
                    data-vv-as="category"
                    hide-details
                    :error-messages="errors.collect('category')"
                    v-validate="'required'"
                  >
                  </HbSelect>
                </hb-form>

                <!-- Amenity Options -->
                <hb-form full label="Option">
                  <!-- Radio options -->
                  <HbRadioGroup
                    v-if="amenityDetail && amenityDetail.property_options"
                    v-model="amenityDetail.property_options.type"
                    class="ma-0 pa-0"
                    v-validate="'required'"
                    hide-details
                    id="Option"
                    name="Option"
                    @change="clearOptions"
                  >
                    <template v-slot:label>
                      For a given space, a feature/amenity can be either:
                    </template>
                    <HbRadio value="checkbox" class="pb-3" :checked=" amenityDetail.property_options.type == 'checkbox'">
                      <template v-slot:label>
                        <template>
                          <div style="position: relative; top: 8px">
                            <div>Yes or No</div>
                            <div style="color: #637381; font-size: 12px;">
                              e.g. Climate Controlled
                            </div>
                          </div>
                        </template>
                      </template>
                    </HbRadio>
                    <HbRadio value="select" class="pb-3">
                      <template v-slot:label>
                        <template>
                          <div style="position: relative; top: 8px">
                            <div>Multiple Options</div>
                            <div style="color: #637381; font-size: 12px;">
                              e.g. Door Type can be Roll-up Door, Swing Door
                              or Curtain Door
                            </div>
                          </div>
                        </template>
                      </template>
                    </HbRadio>
                  </HbRadioGroup>
                  
                  <!-- Add a Custom Option -->
                  <div
                    class="col-12"
                    v-if="amenityDetail && amenityDetail.property_options && amenityDetail.property_options.type === 'select'"
                  >
                    <div>
                      <v-row
                        :key="index"
                        v-for="(option, index) in amenityOptions"
                        :class="{
                          'custom-option': errors.first(`Option-${index + 1}`)
                        }"
                      >
                        <v-col md="5">
                          <HbTextField
                            class="pt-0 mt-0 pl-6 pb-2"
                            v-model="amenityOptions[index]"
                            single-line
                            label="Add Custom Option"
                            v-validate="`required`"
                            :data-vv-name="`Option-${index + 1}`"
                            :data-vv-as="`Option-${index + 1}`"
                            :readonly="amenitiesLength < index + 1 ? false : true "
                            :error-messages="
                              errors.collect(`Option-${index + 1}`)
                            "
                            :id="`Option-${index + 1}`"
                            :name="`Option-${index + 1}`"
                            hide-details
                          ></HbTextField>
                        </v-col>

                        <v-col md="5">
                          <hb-combobox
                              v-model="amenityOptionsSST[index]"
                              :items="getOtherUnitTypes"
                              :id="`Secondary Space Type ${index + 1}`"
                              :name="`Secondary Space Type ${index + 1}`"
                              select
                              item-text="name"
                              item-value="id"
                              data-vv-name="`Secondary Space Type ${index + 1}`"
                              data-vv-as="`Secondary Space Type ${index + 1}`"
                              label="Select a Type"
                          > 
                          </hb-combobox>
                        </v-col>

                        <v-col md="2">
                          <v-icon
                            color="primary"
                            v-if="amenityOptions.length > 1"
                            size="medium"
                            @click="removeOption(index)"
                            :class="{
                              'custom-option-icon': errors.first(
                                `Option-${index + 1}`
                              )
                            }"
                          >
                            mdi-close
                          </v-icon>
                        </v-col>
                      </v-row>
                    </div>

                    <div class="mt-4">
                      <span class="primary--text" @click="addOption()" style="cursor: pointer"
                        ><v-icon color="primary" size="small">mdi-plus</v-icon
                        >Add A Custom Option</span
                      >
                    </div>
                  </div>
                
                  <!-- Primary and Derived Options -->
                  <div class="row">
                    <div
                      class="col">
                      <span class="row">
                        <template>
                          <div class="hb-text-light hb-font-body ml-4 derived-selection" >
                            Derived amenities are amenities that are linked to other amenities.
                          </div>
                        </template>
                        <HbCheckbox
                          :disabled="!hasPermission('edit_drive_amenity') && editMode"
                          v-model="amenityDetail.is_derived"
                          @change="handleIsDriveChange"
                          label="Is this a derived amenity?"
                          data-vv-name="Is Derived "
                          class="ml-4"
                      />
                      <hb-tooltip>
                        <template v-slot:header>
                          Derived amenities are amenities that are linked to other amenities.
                        </template>
                    </hb-tooltip>
                    </span>
                    </div>
                  </div>

                  <!-- ask for primary amenity if user selects derived -->
                  <div class="row py-0 my-0">
                    <div
                      class="col-12 mt-0 py-0"
                      v-show="amenityDetail && isDerivedAmenity"
                      >
                      <template>
                        <div
                          class="mt-0 hb-font-body hb-text-light"
                        >
                          Select primary amenity to link to
                        </div>
                      </template>
                      <HbSelect
                        :items="parent_amenity_list_to_shown"
                        item-text="master_amenity_name"
                        item-value="amenity_id"
                        single-line
                        label="Select a Type"
                        v-model="amenityDetail.parent_amenity_id"
                        name="parent_amenity_id"
                        id="parent_amenity_id"
                        data-vv-name="parent_amenity_id"
                        data-vv-as="Primary Amenity"
                        hide-details
                        :error-messages="errors.collect('parent_amenity_id')"
                        v-validate="isDerivedAmenity ? 'required' : ''"
                        style="max-width: 300px;"
                        :disabled="!hasPermission('edit_drive_amenity') && editMode"
                        @change="handleParentAmenityChange" 
                      >
                      </HbSelect>
                    </div>
                  </div>

                  <!-- is it 1to1 or range -->
                  <div class="row">
                    <div
                      class="col-12"
                      v-show="amenityDetail && isDerivedAmenity"
                      >
                      <template>
                        <div class="hb-font-body hb-text-light">
                          Select value mapping 
                          <span v-show="disable_value_mapping">
                            <hb-tooltip>
                              <template v-slot:header>
                                Only Range Mapping Can be Given to System Level Amenities.
                              </template>
                            </hb-tooltip>
                        </span>
                        </div>
                       
                      </template>
                      <hb-radio-group 
                        row 
                        v-if="amenityDetail"
                        v-model="amenityDetail.value_mapping"
                        class="ma-0 pa-0"
                        @change="handleValueMappingChange"
                        :disabled="!hasPermission('edit_drive_amenity') && editMode"
                        v-validate="isDerivedAmenity && !amenityDetail.value_mapping ? 'required' : ''"
                        :error-messages="errors.collect('value_mapping')"
                        hide-details
                        id="value_mapping"
                        name="value_mapping"
                        data-vv-name="value_mapping"
                        data-vv-as="Value Mapping"
                      >
                        <hb-radio value="one_to_one" :disabled="disable_value_mapping" label="Value to value"></hb-radio>  
                        <hb-radio value="range" :checked="disable_value_mapping" label="Range"></hb-radio>  
                      </hb-radio-group>
                    </div>
                  </div>
<!-- style="margin-bottom: 2px;" -->
                  <!-- maping for 1 to 1 or range -->
                  <div class ="row mb-0.5" v-show="primary_amenity  &&  amenityDetail.value_mapping && isDerivedAmenity">
                    <div class="col-6" >
                      <template>
                        <div class="hb-font-body hb-text-light">
                          Derived amenity: {{ amenityDetail?.property_amenity_name }}
                        </div>
                      </template>
                    </div>
                    <div class="col-6" >
                      <template>
                        <div class="hb-font-body hb-text-light">
                          Primary amenity: {{ primary_amenity?.master_amenity_name }}
                        </div>
                      </template>
                    </div>

                  </div>
                  <span  v-show="primary_amenity && amenityDetail.value_mapping && amenityDetail.value_mapping === 'one_to_one' && isDerivedAmenity">
                    <v-row class="mb-0" style="margin-top: -1px;" v-for="(opt, index) in total_onetoone_mappings" :key="index">
                      <v-col cols="5" class="d-flex justify-center align-center">
                          <HbSelect
                            :items="updatedOptions"
                            single-line
                            label="Please select Drive Amenity Value"
                            :hide-details="true"
                            v-model="amenity_mapings[index]"
                            :disabled="!hasPermission('edit_drive_amenity') && editMode"
                            :data-vv-name="`derived_mapping_${index + 1}`"
                            data-vv-as="Derived mapping"
                            v-validate="isDerivedAmenity ? 'required' : ''"
                            :error-messages="errors.collect(`derived_mapping_${index + 1}`)"
                            style="max-width: 250px;"
                            class="py-0 my-0 "
                            dense
                          >
                          </HbSelect>
                      </v-col>
                      <v-col cols="1" class="d-flex align-center">
                        =
                      </v-col>
                      <v-col cols="5" class="d-flex align-center">
                        <hb-combobox
                          label="Select Primary Amenity Value"
                          v-model="primary_amenity_options[index]"
                          :items="filter_primary_amenity_options[index]"
                          :disabled="!hasPermission('edit_drive_amenity') && editMode"
                          :key="comboKey"
                          :error="mapping_alert"
                          select
                          multiple
                          return-object
                          @change="filterdPrimaryAmenityOptions(index)"
                        >
                          <template v-slot:item="{ item }">                
                            <v-list-item-action>
                              <v-icon v-if="isSelected(item)">check_box</v-icon>
                              <v-icon v-else>check_box_outline_blank</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ item }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </hb-combobox>
                      </v-col>
                      <v-col cols="1" class="d-flex align-center m-actions"> <hb-icon :disabled="!hasPermission('edit_drive_amenity') && editMode"
                        @click="removeMapping(index, index)" small>mdi-close</hb-icon></v-col>                           
                  
                    </v-row>
                  </span>
                  <!-- Add Additional Mapping Button -->
                    <v-row class="justify-end" v-show="primary_amenity && amenityDetail.value_mapping && amenityDetail.value_mapping === 'one_to_one' && isDerivedAmenity && !allPrimaryOptionsSelected" style="margin-right: 18px;">
                    <hb-link class="mt-1 pl-2" :disabled="!hasPermission('edit_drive_amenity') && editMode"
                    @click="addAdditionalMapping" style="text-align: right;">+ Add Additional Mapping</hb-link>
                    </v-row>

                  <v-row class="mb-0"  style="height: 5vh;" v-show="primary_amenity && amenityDetail.value_mapping && amenityDetail.value_mapping === 'range' && isDerivedAmenity" v-for="(t, index) in tiers" :key="index">
                      <v-col class="d-flex justify-center align-center m-text">  
                      <HbSelect
                          :items="updatedOptions"
                          single-line
                          label="Please select Drive Values"
                          :hide-details="true"
                          :disabled="!hasPermission('edit_drive_amenity') && editMode"
                          v-model="amenity_mapings[index]"
                          :data-vv-name="`derived_mapping${index + 1}`"
                          data-vv-as="Derived mapping"
                          v-validate="isDerivedAmenity ? 'required' : ''"
                          style="width: 220px;"
                          :error-messages="errors.collect(`primary_mapping_${index + 1}`)"
                          class="py-0 my-0 "
                          dense
                        >
                        </HbSelect>
                        </v-col>
                    <!-- </div> -->
                  
                    <v-col  class="d-flex justify-center align-center m-equal"> = </v-col>

                    <v-col  class="d-flex justify-center align-center m-to">{{ minSqft(t, index)}}</v-col>
                    
                    <v-col class="d-flex justify-center align-center" >to </v-col>

                    <v-col class="d-flex justify-center align-center m-text-2">
                      <HbTextField
                          class="ma-0 pa-0"
                          dense
                          v-model="tiers[index]"
                          @change="handleMappingFieldChange"
                          :disabled="!hasPermission('edit_drive_amenity') && editMode"
                          v-validate.disable="'required'"
                          data-vv-scope="tiers"
                          id="name"
                          name="name" 
                          :error="!!tierValidationErrors.length"
                          single-line
                          hide-details>
                      </HbTextField>                  
                    </v-col>
                    <v-col class="m-actions"> <hb-icon @click="removeTier(index, index)" :disabled="!hasPermission('edit_drive_amenity') && editMode"
                      small>mdi-close</hb-icon></v-col>                           
                  </v-row>

              <v-row v-show="primary_amenity && amenityDetail.value_mapping && amenityDetail.value_mapping === 'range' && isDerivedAmenity">
                   <v-col class="d-flex justify-center align-center" >
                        <HbSelect
                          :items="updatedOptions"
                          single-line
                          label="Please select Drive Value"
                          :hide-details="true"
                          v-model="amenity_mapings[tiers.length]"
                          :data-vv-name="`derived_mapping_${tiers.length + 1}`"
                          :disabled="!hasPermission('edit_drive_amenity') && editMode"
                          data-vv-as="Derived mapping"
                          v-validate="isDerivedAmenity ? 'required' : ''"
                          style="width: 220px;"
                          :error-messages="errors.collect(`primary_mapping_${tiers.length + 1}`)"
                          class="py-2 my-0 "
                          dense
                        >
                        </HbSelect>
                  </v-col>
                  
                  <v-col class="d-flex justify-center align-center" cols="1" > = </v-col>

                   <v-col class="d-flex justify-center align-center">
                          {{ minSqft(tiers[tiers.length], tiers.length)}} and above
                   </v-col> 

              </v-row>  

              <v-row class="justify-end" v-show="primary_amenity && amenityDetail.value_mapping && amenityDetail.value_mapping === 'range' && isDerivedAmenity" style="margin-right: 18px;">
                        <hb-link class="mt-1 pl-2" @click="addTier" style="text-align: right;">+ Add Additional Tier</hb-link>                                            
              </v-row>   

                </hb-form>
              </div>
            </v-stepper-content>

            <v-stepper-content class="pa-0" key="2-content" step="2">
              <div v-if="stepper === 2" style="min-height: 300px">
        
                <hb-form :label="amenityDetail.property_category_name">
                  <v-row>
                    <v-col class="pa-1 pl-1" cols="1" v-show="isDerivedAmenity">
                      <hb-tooltip v-show="isDerivedAmenity">
                          <template v-slot:header>
                          You Cannot Set the default value for Derived Amenity.
                          </template>
                      </hb-tooltip>
                    </v-col>
                    <v-col class="pa-1 pl-5" cols="5">
                      <span>{{ amenityDetail.property_amenity_name }}</span>
                    </v-col>
                    <v-col class="pa-0 pl-5" cols="6">
                      <HbSelect
                        v-if="amenityDetail && amenityDetail.property_options && amenityDetail.property_options.type == 'select' "
                        class="pt-0"
                        :items="amenityOptions"
                        v-model="amenityDetail.property_default_value"
                        :disabled="isDerivedAmenity"
                        item-value="id"
                        item-text="name"
                        single-line
                        label="Select a value"
                        v-validate="isDerivedAmenity ? '' : 'required'"
                        :error-messages="errors.collect('Default Value')"
                        hide-details
                        id="Default Value"
                        name="Default Value"
                      >
                      </HbSelect>
                      <HbRadioGroup
                        v-else
                        row
                        v-model="amenityDetail.property_default_value"
                        class="ma-0 pa-1"
                        :v-validate=" isDerivedAmenity ? '' : 'required'"
                        hide-details
                        :error-messages="errors.collect('Default Value')"
                        id="Default Value"
                        name="Default Value"
                      >
                        <hb-radio value="Yes" class="pb-3" :disabled="isDerivedAmenity">
                          <template v-slot:label>
                            <span class="text-capitalize">Yes</span>
                          </template>
                        </hb-radio>
                        <hb-radio value="No" class="pb-3" :disabled="isDerivedAmenity">
                          <template v-slot:label>
                            <span class="text-capitalize">No</span>
                          </template>
                        </hb-radio>
                      </HbRadioGroup>
                    </v-col>
                  </v-row>
                </hb-form>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </hb-stepper>
      </template>

      <!-- Buttons -->
      <template v-slot:right-actions>
        <span class="">
          <hb-btn
            v-if="stepper === 2"
            color="secondary"
            @click="back"
            >Back</hb-btn
          >
          <hb-btn color="primary" type="submit" @click="next" :disabled="isLoading('update_amenity')" :loading="isLoading('update_amenity')">
            {{ stepper === 2 ? "Save" : "Next" }}
          </hb-btn>
        </span>
      </template>
    </hb-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import api from "../../../assets/api.js";
import { notificationMixin } from  '@/mixins/notificationMixin.js';
import { EventBus } from '../../../EventBus.js';


export default {
  name: "EditFeatureAndAmenity",
  computed: {
    ...mapGetters({
      addedFeaturesList: "featureAndAmenitiesStore/addedFeaturesList",
      allCategoriesFeaturesList: "featureAndAmenitiesStore/allCategoriesFeaturesList",
      hasPermission: 'authenticationStore/rolePermission'
    }),
    dialog: {
      get() {
        return this.value;
      },
      set() {}
    },
    allPrimaryOptionsSelected() {
      if(this.primary_amenity?.options?.type == 'text') return;
      const flattenedOptions = [];
      this.primary_amenity_options.forEach(optionArray => {
        flattenedOptions.push(...optionArray);
      });

      return (flattenedOptions?.length == this.primary_amenity?.options?.options?.length)
    },
    updatedOptions() {
      if (this.amenityDetail?.property_options?.type === 'checkbox') {
        return ['Yes', 'No'];
      }
      return this.amenityOptions.map((option) => {
        if (option.toLowerCase() === "yes") {
          return "Yes";
        }
        if (option.toLowerCase() === "no") {
          return "No";
        }
        return option;
      });
    },
    getOtherUnitTypes(){
      let temp = this.getUnitTypes().filter(item => item.unit_type_id != this.amenityDetail?.unit_type_id)
      return temp.map(i => {
        return {
          ...i,
          id:i.unit_type_id,
          name:i.display_name
        }
      })
    }
  },
  props: {
    value: {
      type: Boolean
    },
    property_id: {
      type: String
    },
    amenity_id: {
      type: String
    },
    activeTab: {
      type: Object
    }
  },
  data() {
    return {
      editMode: false,
      countAmenitiesAdded: 0,
      amenitiesLength: 0,
      tabOptions: [
        { label: "Define Values", key: 1 },
        { label: "Apply Defaults", key: 2 }
      ],
      stepper: 1,
      maxActiveStep: 1,
      showConfirmationDialog: false,
      steps: [
        { number: 1, status: "step-active" },
        { number: 2, status: "step-disabled" }
      ],
      categories: [],
      amenityDetail: {value_mapping: null,parent_amenity_id:null},
      amenityOptions: [],
      amenityOptionsSST: [],
      initialOption: {},
      total_onetoone_mappings: [''],
      range_amenity_options: [],
      primary_amenity_options: [[]],
      filter_primary_amenity_options: [[]],
      mapping_alert: false,
      amenity_mapings: [],
      primary_amenity: [],
      tiers: [],
      loading: false,
      tierValidationErrors: [],
      comboKey: 0,
      isDerivedAmenity: false,
      disable_value_mapping: false,
      parent_amenity_list_to_shown: [],
      all_siblings_amenities: [],
    };
  },
  mixins: [notificationMixin],
  async created() {
    if (this.amenity_id) {
      this.editMode = true;
    }
    this.fetchCategories();
    if (this.editMode) {
      await this.fetchAllSiblingsAmenities(this.property_id, this.amenity_id);
      await this.setParentAmenitiesList();
      await this.fetchAmenityDetails();
    } else {
      this.amenityDetail = this.newAmenityObject();
      this.amenityDetail.property_options.type = "checkbox" 
    }
  },
  methods: {
    ...mapActions({
      fetchFeatures: "featureAndAmenitiesStore/fetchFeatures",
      fetchAllCategoriesFeatures: "featureAndAmenitiesStore/fetchAllCategoriesFeatures",
    }),
    newAmenityObject() {
      return {
        property_amenity_name: "",
        property_category_name: "",
        amenity_category_id: "",
        property_id: this.property_id,
        options: "",
        property_options: {
          options: [],
          type: ""
        },
        property_default_value: "Yes",
        unit_type_id: this.activeTab.unit_type_id,
        property_type: this.activeTab.value,
        is_derived: false
      };
    },
    minSqft(tier, i){
                if(i === 0) return 0;
        return this.tiers[i-1];
    }, 
    addTier(){
      if(!this.hasPermission('edit_drive_amenity') && this.editMode) return;
                this.tiers.push('')
    },
    removeTier(t, i){
      if(!this.hasPermission('edit_drive_amenity') && this.editMode) return;
                this.tiers.splice(i, 1)
                this.amenity_mapings.splice(i, 1);
    }, 
    removeMapping(m, i){
      if(!this.hasPermission('edit_drive_amenity') && this.editMode) return;
        this.amenity_mapings.splice(i, 1);
        this.primary_amenity_options.splice(i, 1);
        this.filter_primary_amenity_options.splice(i,1);
        this.total_onetoone_mappings.splice(i, 1);
        this.filterdPrimaryAmenityOptions(i);
    },
    validateTiers(){
                let errors = [];
                if(!this.amenityDetail.is_derived) return [];
                    if(!this.tiers.length){
                        errors.push['Please include as least 1 area tier.']
                    }    
                    if(this.tiers.findIndex(t => !t) >= 0){
                      errors.push('Please enter all the tiers you want to include.');
                    }

                    if (this.tiers?.some(t => t < 0)) {
                      errors.push('Tiers value cannot be negative.');
                    }
                    if(this.tiers?.length) {
                    let sorted = true; 
                    let whole = true; 
                    for (let i = 0; i < this.tiers?.length; i++) {  
                        if (+this.tiers[i] >= +this.tiers[i+1]) {
                                sorted = false;
                        }
                        if (Math.floor(this.tiers[i]) !== +this.tiers[i] ) {
                            whole = false;
                        }
                    }
                    if (!sorted){
                        errors.push('Your tiers must be sorted lowest to highest'); 
                    }
                    if (!whole){
                        errors.push('Please use whole numbers for your tiers'); 
                    }
                  }
                    this.tierValidationErrors = errors;
        return errors;
    },
    async fetchAllSiblingsAmenities(property_id, amenity_id){
          try {
            const res = await api.get(
                this.$app,
                api.PROPERTY_AMENITIES + `${property_id}/get-all-siblings-amenities/${amenity_id}`
              );
              this.all_siblings_amenities = res?.all_siblings_amenities.map(am => am.id);
          } catch(error) {
              console.log(error);
          }
    },
    async handleIsDriveChange(value) {
      this.isDerivedAmenity = !this.isDerivedAmenity;

      if(!this.isDerivedAmenity){
        this.primary_amenity = [];
        this.resetParentAmenitySettings();
      }

      if(!this.editMode)
      await this.setParentAmenitiesList();
    },
    fetchCategories() {
      api
        .get(this, api.SM_CATEGORIES)
        .then(response => {
          this.categories = response.categories.map(category => ({
            ...category,
            nameWithType: `${category.name} (${category.type})`
          }));
        })
        .catch(function(err) {
          throw err;
        });
    },

    filterdPrimaryAmenityOptions(index) {
      if(this.primary_amenity?.options?.type == 'text') return;
      this.mapping_alert = false;
      const flattenedOptions = [];
      this.primary_amenity_options.forEach(optionArray => {
        flattenedOptions.push(...optionArray);
      });
      if (flattenedOptions.length === 0) {
        this.filter_primary_amenity_options[index] = this.primary_amenity?.options?.options;
      } else {
        for (let i = 0; i < this.filter_primary_amenity_options.length; i++) {
          this.filter_primary_amenity_options[i] = [...this.primary_amenity_options[i], ...this.primary_amenity?.options?.options.filter(option => 
            !flattenedOptions.includes(option) 
          )];
        }
      }
    },

    async setParentAmenitiesList() {
      await this.fetchAllCategoriesFeatures({
            property_id: this.property_id,
            unit_type_id: this.activeTab.unit_type_id
          });
      this.parent_amenity_list_to_shown = this.allCategoriesFeaturesList;
    },

    isSelected(item) {
      let isSelected = false;
      const flattenedOptions = [];
      this.primary_amenity_options.forEach(optionArray => {
        flattenedOptions.push(...optionArray);
      });
      
      if (flattenedOptions.length === 0) {
        return false;
      }
      this.primary_amenity_options.forEach(optionArray => {
        if (optionArray.includes(item)) {
          isSelected = true;
        }
      });
      return isSelected;
    },

    addAdditionalMapping() {
      if(this.primary_amenity?.options.type == 'text') return;
      if(!this.hasPermission('edit_drive_amenity') && this.editMode) return;
      const flattenedOptions = [];
      this.primary_amenity_options.forEach(optionArray => {
        flattenedOptions.push(...optionArray);
      });

      this.total_onetoone_mappings.push(null); 
      this.primary_amenity_options.push([]);
   
      this.filter_primary_amenity_options.push([...this.primary_amenity?.options?.options.filter(option => 
      !flattenedOptions.includes(option))]);
    },

    fetchAmenityDetails() {
      api
        .get(
          this,
          `${api.PROPERTY_AMENITIES}${this.property_id}/amenities/${this.amenity_id}`
        )
        .then(response => {
          this.amenityDetail = response.amenity;
          this.amenityDetail.property_options = JSON.parse(
            this.amenityDetail.property_options
          );
          this.amenityOptions = this.amenityDetail.property_options
            .options
            ? this.amenityDetail.property_options.options
            : [""];

          this.amenityOptionsSST = this.amenityDetail.secondary_space_type_options
            ? this.amenityDetail.secondary_space_type_options.map(item => item?.unit_types)
            : this.amenityDetail.property_options.options.map(i => []);

            this.initialOption = Object.assign({}, this.amenityDetail.property_options);
            if(this.amenityDetail?.parent_amenity_id) { 
              if(this.amenityDetail.property_options.type == 'text') {
                this.disable_value_mapping = true;
              }
                 this.isDerivedAmenity = true;   
                if (this.initialOption?.mapping_info) {
                  this.amenityDetail.is_derived = true;
                  this.loading = true;
                  this.amenityDetail.value_mapping = this.initialOption.mapping_info.type;

                  if (this.initialOption.mapping_info.type === 'one_to_one') {
                    const flattenedOptions = [];
                    this.primary_amenity_options = Array(this.initialOption.mapping_info.mappings?.length).fill([]);
                    this.filter_primary_amenity_options = Array(this.initialOption.mapping_info.mappings?.length).fill([]);
                    this.total_onetoone_mappings = Array(this.initialOption.mapping_info.mappings?.length).fill([]);
                    for(let j = 0 ; j < this.initialOption?.mapping_info?.mappings?.length; j++) {
                      const m = this.initialOption.mapping_info.mappings[j];
                      this.primary_amenity_options[j] = m.primary_value;
                      flattenedOptions.push(...m.primary_value);
                    }
                    this.amenity_mapings = this.initialOption.mapping_info.mappings.map(m => m.derived_value);
                    for(let i = 0 ; i < this.initialOption?.mapping_info?.mappings?.length; i++) {
                      this.filter_primary_amenity_options[i] = [...this.primary_amenity_options[i]];
                    }
                    } else if (this.initialOption.mapping_info.type === 'range') {
                   
                    this.amenity_mapings = this.initialOption.mapping_info.mappings.map(m => m.derived_value);

                    this.tiers = [...this.initialOption.mapping_info.mappings
                     .slice(0, -1)  
                     .map(m => Number(m.primary_end_value))];                     
                  }
                }  
          }
            this.amenitiesLength = this.amenityOptions.length ;
            this.loading = false;
            this.parent_amenity_list_to_shown = this.allCategoriesFeaturesList.filter(
              item => item.property_amenity_mapping_id !== this.amenityDetail.property_amenity_mapping_id &&
                      !this.all_siblings_amenities.includes(item.property_amenity_mapping_id)
            );
        })
        .catch(function(err) {
         
          throw err;
        });
    },

    createAmenity() {
      this.amenityDetail.options = {
        type: this.amenityDetail.property_options.type,
        options: this.updatedOptions
      };
      const amenity = this.amenityDetail;
      var default_value = ''
      if(!this.amenityDetail.is_derived) {
      default_value =
        amenity.property_default_value.toLowerCase() === "yes" ? "Yes" :
        amenity.property_default_value.toLowerCase() === "no" ? "No" :
        amenity.property_default_value;
      }
      let body = {
        name: amenity.property_amenity_name.trim(),
        unit_type_id: amenity.unit_type_id,
        unit_type_display_name: this.getUnitTypeTitle({unit_type_id:amenity.unit_type_id}),
        category_id: amenity.amenity_category_id,
        options: amenity.options,
        property_id: amenity.property_id,
        default: default_value,
        sort_order: this.addedFeaturesList.length + 1,
        is_derived: amenity.is_derived, 
        parent_amenity_id: amenity.parent_amenity_id, 
      };

      if(amenity.options.type === 'select'){
        body.options.secondy_space_type = this.amenityOptions.map((item,x) => {
          return {
            option: item,
            unit_types: this.amenityOptionsSST[x] ? 
              this.amenityOptionsSST[x].map(k => ({ id: (typeof k == 'object') ?  Object.keys(k).length > 1 ? k.unit_type_id: k.id : k })) 
              : []
          }
        });
      }

      if (this.amenityDetail.is_derived) {
        body.options.mapping_info = {
          type: this.amenityDetail.value_mapping, 
          mappings: this.amenity_mapings.map((driveOption, index) => {
            if (this.amenityDetail.value_mapping === 'one_to_one') {
              return {
                derived_value: driveOption,
                primary_value: this.primary_amenity_options[index], 
              };
            } else if (this.amenityDetail.value_mapping === 'range') {
              return {
                derived_value: driveOption,
                primary_start_value: index === 0 ? 0 : this.tiers[index - 1], 
                primary_end_value: index === this.tiers.length ? Infinity : this.tiers[index] 
              };
            }
          })
        };
      }
      api
        .post(this, api.SPACE_MANAGEMENT + "amenities", body)
        .then(res => {
          this.stopLoading('update_amenity');
          this.$store.dispatch('reportStore/clearReportStructure');
          this.$store.dispatch('filterStore/clearSizes');
          EventBus.$emit('refetch_data')
          this.$emit("close");
        })
        .catch(err => {
          this.showMessageNotification({type: 'error', description: err});
        });
    },

    updateAmenity() {
      let payload = { ...this.amenityDetail };

      if (!this.amenityDetail.is_derived) {

        if('property_default_value' in payload && payload.property_default_value != null)
          payload.property_default_value =
            payload.property_default_value.toLowerCase() === "yes" ? "Yes" :
            payload.property_default_value.toLowerCase() === "no" ? "No" :
            payload.property_default_value;
        else{
          this.showMessageNotification({type: 'error', description: 'Please select default value.'});
          this.stopLoading('update_amenity');
          return;
        }
      } else {
        payload.property_default_value = '';
      }

      payload.property_amenity_name = payload.property_amenity_name.trim();

      payload.is_derived = this.amenityDetail.is_derived;
      payload.parent_amenity_id = this.amenityDetail.parent_amenity_id;
       
      if (this.amenityDetail.is_derived) {
        payload.property_options.mapping_info = {
          type: this.amenityDetail.value_mapping,
          mappings: this.amenity_mapings.map((driveOption, index) => {
            if (this.amenityDetail.value_mapping === 'one_to_one') {
              return {
                derived_value: driveOption,
                primary_value: this.primary_amenity_options[index],
              };
            } else if (this.amenityDetail.value_mapping === 'range') {
              return {
                derived_value: driveOption,
                primary_start_value: index === 0 ? 0 : this.tiers[index - 1], 
                primary_end_value: index === this.tiers.length ? Infinity : this.tiers[index] 
              };
            }
          })
        };
      } else {
        payload.parent_amenity_id = null;
        payload.value_mapping = null;

        if (payload.property_options.type === "select") {
              payload.property_options = {
                type: payload.property_options.type,
                options: payload.property_options.options
              };
              payload.options = {
                type: payload.property_options.type,
                options: payload.property_options.options
              }
         } else {
              payload.property_options = {
                type: 'checkbox',
                options: ['Yes', 'No']
              };
              payload.options = {
                type: 'checkbox',
                options: ['Yes', 'No']
              }
        }
      }
     
      payload.property_options.secondy_space_type = this.amenityOptions.map((item,x) => {
        return {
          option: item,
          unit_types: this.amenityOptionsSST[x] ? 
              this.amenityOptionsSST[x].map(k => ({ id: (typeof k == 'object') ?  Object.keys(k).length > 1 ? k.unit_type_id: k.id : k })) 
              : []
        }
      })

      api.put(this, api.UPDATE_AMENITY + payload.property_amenity_mapping_id, payload)
        .then(message => {
         
          this.stopLoading('update_amenity');
          this.showMessageNotification({type: 'success', description: message});
          this.$store.dispatch('reportStore/clearReportStructure');
          this.$store.dispatch('filterStore/clearSizes');
          EventBus.$emit('refetch_data')
          this.fetchFeatures({
            property_id: this.property_id,
            unit_type_id: this.activeTab.unit_type_id
          });
          this.$emit("close");
        })
        .catch(err => {
          this.showMessageNotification({type: 'error', description: err});
          this.back();
        });
    },

    next() {
      this.checkDefaultValue();

      if(this.amenityDetail.is_derived){
        if (this.amenityDetail?.value_mapping === 'range') {
          let errors = this.validateTiers();
          if(errors?.length){
            errors.forEach(error => {
              this.showMessageNotification({type: 'error', description: error});
            });
            return;
          }
        }

        if (this.amenityDetail?.value_mapping === 'one_to_one') {
          if(!this.allPrimaryOptionsSelected) {
            this.mapping_alert = true;
            this.showMessageNotification({type: 'error', description: "Every Primary value must map to the drive value"});
            return;
          }
        }
      }

      this.$validator.validateAll().then(status => {
        if (status) {
          if (this.stepper == 1) {
            if( this.editMode && this.amenityDetail.amenity_assigned_count && this.amenityDetail.property_options.type !== this.initialOption.type) {
              let desc = 'This feature or amenity is applied to spaces, you can not change the option.';
              this.showMessageNotification({type: 'error', description: desc});
            }
            else if (this.amenityDetail.amenity_category_id && this.checkDuplicateOptions()) {
              this.amenityDetail.property_category_name = this.categories.find(
                category => category.id == this.amenityDetail.amenity_category_id
              ).name;
              this.goToStep(this.stepper + 1, true);
            }
          } else {
            this.startLoading('update_amenity');
            this.editMode ? this.updateAmenity() : this.createAmenity();
          }
        } else if (this.stepper === 2 && !this.amenityDetail.property_default_value) {
          if(this.amenityDetail.is_derived) return;
          let desc = 'There are errors in your form, correct them before continuing.';
          let errList = [`${this.amenityDetail.property_category_name} is missing values for selected options.`];
          this.showMessageNotification({type: 'error', description: desc, list: errList});
        }
      });
    },

    handleMappingFieldChange(newValue) {
      this.tierValidationErrors = [];
    },

    isCheckBoxAmenity(options) {
        if(options == 'checkbox') {
          this.primary_amenity.options.options = ['Yes', 'No'];
      }
    },
    isSystemLevelAmenity(){
      this.amenityDetail.value_mapping = 'range';
        this.disable_value_mapping = true;
        this.primary_amenity_options = Array(1).fill([])
        this.filter_primary_amenity_options = Array(1).fill([])
        this.tiers = []; 
        this.addTier(); 
    },

    handleValueMappingChange(newValue) {
    this.amenityDetail.value_mapping = newValue;

    this.isCheckBoxAmenity(this.primary_amenity?.options.type);

    if(this.primary_amenity?.options.type == 'text') {
      this.isSystemLevelAmenity();
      } else {
        this.disable_value_mapping = false;      
        this.primary_amenity_options = this.primary_amenity?.options?.options ? Array(this.total_onetoone_mappings?.length).fill([]) : [[]];
        this.filter_primary_amenity_options = this.primary_amenity?.options?.options.length ? Array(this.total_onetoone_mappings?.length).fill([...this.primary_amenity.options.options]) : [[]];
        this.tiers = [];
        if (newValue === 'range' && this.amenity_mapings) {
          const numberOfOptions = this.amenity_mapings.length - 1;
          for (let i = 0; i < numberOfOptions; i++) {
            this.addTier();
          }
        }
  }
  },

    removeOption(index) {
      this.amenityOptions.splice(index, 1);
      this.amenityOptionsSST.splice(index, 1);
      if(index < this.amenitiesLength) this.amenitiesLength = this.amenitiesLength - 1
      this.amenityDetail.property_default_value = ""
    },
    addOption() {
      this.amenityOptions.push("");
      this.amenityOptionsSST.push("");
    },
    async goToStep(stepNumber, previousStepCompleted) {
      const maxStep = 3;
      if (stepNumber > maxStep) {
        return;
      }
      if (previousStepCompleted) {
        this.steps[stepNumber - 1].status = "step-active";
        this.steps[stepNumber - 2].status = "step-complete";
        this.stepper = stepNumber;
        this.maxActiveStep = stepNumber;
      } else {
        if (stepNumber === 1 || stepNumber <= this.maxActiveStep) {
          this.stepper = stepNumber;
          this.steps[stepNumber - 1].status = "step-active";

          for (let i = stepNumber + 1; i <= this.maxActiveStep; i++) {
            this.steps[i - 1].status = "step-editable";
          }

          for (let j = stepNumber - 1; j > 0; j--) {
            this.steps[j - 1].status = "step-complete";
          }
        }
      }
    },

    clearOptions() {
      this.amenityDetail.property_default_value = null;
      this.amenityOptions =
        this.amenityDetail.property_options.options &&
        this.amenityDetail.property_options.options.length > 0
          ? this.amenityDetail.property_options.options
          : [""];
      this.amenityOptionsSST = this.amenityDetail?.property_options?.secondy_space_type
          ? this.amenityDetail?.property_options?.secondy_space_type.map(item => item.unit_types) 
          : [""];
    },
    back() {
      this.goToStep(this.stepper - 1, false);
    },

    onCloseConfirmationDialog() {
      if (this.stepper == 1) {
        this.$emit("close");
      } else {
        this.showConfirmationDialog = true;
      }
    },

    checkDefaultValue() {
      if (this.amenityDetail && this.amenityDetail.property_options && (this.amenityDetail.property_options.type == 'select') && !this.amenityOptions.includes(this.amenityDetail.property_default_value)) {
        this.amenityDetail.property_default_value = null;
      }
    },

    checkDuplicateOptions() {
      let valid = true;
      let v = new Set(this.amenityOptions.map(a => a.toLowerCase().trim()));
      if (this.amenityOptions.length > v.size) {
        valid = false;
        let desc = 'There are errors in your form, correct them before continuing.';
        this.showMessageNotification({type: 'error', description: desc, list: ['Duplicate custom options found.']});
      }
      return valid;
    },
    handleParentAmenityChange(val) {
      this.primary_amenity = this.allCategoriesFeaturesList.find(i => i.amenity_id == val)
      this.isCheckBoxAmenity(this.primary_amenity?.options.type);
  
      if(this.primary_amenity?.options.type == 'text') {
       this.isSystemLevelAmenity();
      } else {
              this.disable_value_mapping = false;
              this.primary_amenity_options = this.primary_amenity?.options?.options ? Array(this.total_onetoone_mappings?.length).fill([]) : [[]];
              this.filter_primary_amenity_options = this.primary_amenity?.options?.options.length ? Array(this.total_onetoone_mappings?.length).fill([...this.primary_amenity.options.options]) : [[]];
              this.tiers = []; 
              if (this.amenity_mapings && this.amenity_mapings.length) {
              const numberOfOptions = this.amenity_mapings.length - 1;
              for (let i = 0; i < numberOfOptions; i++) {
                  this.addTier(); 
                  }
                }    
      } 
    },
    resetParentAmenitySettings(){
      this.amenity_mapings = [];
      this.tiers = [];
      this.primary_amenity_options = [[]];
      this.filter_primary_amenity_options = [[]];
      this.total_onetoone_mappings = [''];
    }

  },
  watch: {
    "errors.items"(val) {
      if(val && val.length) {
        const errorMsg = [];
        let optionsError = false;
        val.forEach((item) => {
          if (item.field.includes('Option-')) optionsError = true;
          else if(item.field == 'Name' && item.rule == 'required') {
            errorMsg.push("Name is required.")
          } else if(item.field == 'category' && item.rule == 'required') {
            errorMsg.push("Category is required.")
          } else if(item.field == 'value_mapping' && item.rule == 'required' && !this.amenityDetail.value_mapping && this.isDerivedAmenity) {
            errorMsg.push("Value mapping is required.")
          } else if(item.field.includes('primary_mapping_') && item.rule == 'required' && this.isDerivedAmenity) {
            errorMsg.push(`Primary value for mapping ${item.field.split('_').pop()} is required.`)
          } else if(item.field.includes('derived_mapping_') && item.rule == 'required' && this.isDerivedAmenity) {
            errorMsg.push(`Derived value for mapping ${item.field.split('_').pop()} is required.`)
          } else if(item.field.includes('range_start_') && item.rule == 'required' && this.isDerivedAmenity) {
            errorMsg.push(`Range start value for mapping ${item.field.split('_').pop()} is required.`)
          } else if(item.field.includes('range_end_') && item.rule == 'required' && this.isDerivedAmenity) {
            errorMsg.push(`Range end value for mapping ${item.field.split('_').pop()} is required.`)
          } else {
            errorMsg.push(item.msg);
          }
        })
        if (optionsError) errorMsg.push('Enter or remove the unfilled custom option.');
        let desc = 'There are errors in your form, correct them before continuing.';
        this.showMessageNotification({type: 'error', description: desc, list: errorMsg});
      }
    },
    'amenityDetail.parent_amenity_id'(val) {
      if(val)
        this.primary_amenity = this.parent_amenity_list_to_shown.find(i => i.amenity_id == val)
        if(this.primary_amenity?.options?.type == 'text')
          this.disable_value_mapping = true;
    },
    'amenityDetail.value_mapping'(newValue) {
      if (newValue) {
        this.errors.remove('value_mapping');
      }
    },
  }
};
</script>

<style lang="scss" scoped>

.custom-option {
  .custom-option-icon {
    color: red !important;
  }
.v-input:not(.v-input--is-dirty) ::label {
    color: red !important;
  }
}
.m-actions {
  padding-top: 1.1em;

}
.derived-selection {
  margin-top: -2px;  
  white-space: nowrap;
}

</style>
